import { encryptSessionStorage } from "@/plugins/pinia.js";
import { computed, onMounted, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { format } from "date-fns";

export function queryMemory(routeName) {
  if (!routeName) return {}; // Handle empty string or null as invalid route names
  const query = encryptSessionStorage?.getItem(`query_${routeName}`);
  try {
    return query ? JSON.parse(query) : {};
  } catch (err) {
    console.error(err);
    return {};
  }
}

export function pushFilterUrl({ filters, route }) {
  let someChanges = false;
  const query = { ...route.query };

  filters.forEach((filter) => {
    if (filter.key == null) return; // Ignore undefined or null keys

    if (filter.value?.toString()?.length > 0) {
      someChanges = true;
      query[filter.key] = filter.value;
    } else if (filter.key in query) {
      delete query[filter.key];
      someChanges = true;
    }
  });
  return { query, someChanges };
}
//Update activeFilter => trigger watch effect => update filters and push to route
export const useFilterUrlBind = (filters, isLoading = false) => {
  const route = useRoute();
  const router = useRouter();

  const activeFilter = computed(() => {
    return filters && !isLoading?.value
      ? Object.keys(filters.value)?.reduce((acc, key) => {
          if (typeof filters.value[key]?.filterUrlKey === "string") {
            if (filters.value[key].value?.some((ele) => ele.index)) {
              acc.push({
                key: filters.value[key]?.filterUrlKey,
                value: filters.value[key].value?.map((v) => v?.index).join(";")
              });
            } else {
              acc.push({
                key: filters.value[key]?.filterUrlKey,
                value: filters.value[key].value?.join(";")
              });
            }
          } else if (Array.isArray(filters.value[key].filterUrlKey)) {
            const filterUrlKeys = filters.value[key].filterUrlKey;
            const filterValues = filters.value[key].value || [];

            filterUrlKeys.forEach((filterUrlKey, index) => {
              let value = filterValues[index] || "";
              if (["endDate", "startDate"].includes(filterUrlKey)) {
                try {
                  // Attempt to format the date
                  value = value ? format(new Date(value), "dd/MM/yyyy") : null;
                  acc.push({ key: filterUrlKey, value });
                } catch (error) {
                  console.error(`Invalid date format: ${value}`);
                  // Skip adding this filter if the date is invalid
                  value = null;
                }
              } else if (value?.toString()?.length > 0) {
                acc.push({ key: filterUrlKey, value });
              }
            });
          }

          return acc;
        }, [])
      : null;
  });
  watchEffect(() => {
    if (!route) return;
    const startDate = route?.query.startDate;
    const endDate = route?.query.endDate;
    const encryptPage = encryptSessionStorage.getItem(`query_${route.name}`);
    if (startDate && endDate) {
      const query = encryptPage ? JSON.parse(encryptPage) : {};
      const newQuery = {
        ...query,
        startDate: startDate,
        endDate: endDate
      };
      encryptSessionStorage.setItem(
        `query_${route.name}`,
        JSON.stringify(newQuery)
      );
    }
  });
  watch(
    () => activeFilter.value,
    (filters, oldFilters) => {
      if (JSON.stringify(filters) === JSON.stringify(oldFilters) || !filters) {
        return;
      }

      const { someChanges, query } = pushFilterUrl({
        filters: filters,
        route
      });

      if (someChanges) {
        setTimeout(() => {
          // Wait until the push queue is empty
          router.push({
            ...route,
            query
          });
        }, 0);
      }
    },
    { immediate: true }
  );
  //Take the filter key from the route and update the filters object in case we pass some query direct from the url at the start

  const updateFiltersFromQuery = () => {
    const query = { ...route.query };

    if (!Object.keys(query) || Object.keys(query)?.length === 0 || !filters) {
      return;
    }

    const tempArrayFilterUrlKey = [];
    //Format the query key to have this format
    //{Related_Challenge: 'query value' || [value1, value2] }
    const formattedQuery = Object.keys(query).reduce((acc, key) => {
      //Find the property in filters object
      const filterObjectKey = Object.keys(filters.value).find((objectKey) => {
        return (
          filters.value[objectKey]?.filterUrlKey === key ||
          (filters.value[objectKey]?.filterUrlKey.includes(key) &&
            Array.isArray(filters.value[objectKey]?.filterUrlKey))
        );
      });
      //Find the filter option for that filter key
      const filterOption = filters.value?.[filterObjectKey];
      if (typeof filterOption?.filterUrlKey === "string") {
        //Query can be multiple like ?Related_Challenge=a0162xxxx;a0162yyy
        acc[filterObjectKey] = query[key]?.split(";");
      } else if (Array.isArray(filterOption?.filterUrlKey)) {
        //If filterUrlKey is an array for example ['startDate','endDate'] => we want to collect all the value in the query
        //Example: Ecosystem_Meeting_Date__c : ['2023-04-01','2023-05-01']
        tempArrayFilterUrlKey.push(query[key]);
        acc[filterObjectKey] = [...tempArrayFilterUrlKey];
      }
      return acc;
    }, {});

    Object.keys(formattedQuery).forEach((key) => {
      const newFilters = filters.value[key]?.parseFilterUrl(
        formattedQuery[key]
      );

      //TODO: ask Nadia if it is really needed for the fallback if parseFilterUrl return nothing
      filters.value[key].value = newFilters;
    });
  };
  watch(
    () => isLoading?.value,
    (loadingState) => {
      if (!loadingState) {
        updateFiltersFromQuery();
      }
    },
    { immediate: true }
  );
};
