<template>
  <div class="flex w-full flex-col items-start">
    <div class="flex gap-4 text-lg font-bold mt-6 mb-4">
      <span> Comments </span>
      <!-- <router-link :to="viewProfile" v-if="isSidebar"> -->
      <base-button
        v-if="isSidebar && !readOnly"
        :text="true"
        label="Add Comment"
        severity="primary"
        icon="add"
        iconClass="w-4 h-4 stroke-primary stroke-2"
        @click="viewProfile"
      />
      <!-- </router-link> -->
    </div>
    <div v-if="isLoading || !comments" class="w-full flex flex-col gap-y-4">
      <base-comment-skeleton v-for="n in 2" :key="n" />
    </div>
    <div v-else-if="comments.length === 0">
      <fallback-empty-state
        classes="p-0"
        textClasses="text-grey1 text-base"
        :isRow="false"
        message="
        No comments have been added yet.
      "
      />
    </div>
    <div v-else class="w-full flex flex-col gap-y-4">
      <base-comment
        v-for="(comment, key) in comments"
        :key="key"
        :isMtber="!!comment.MTBer_Email__c || !comment.Contact__c"
        :user="comment.MTBer_Email__c || comment.Contact__c"
        :time="comment.SystemModstamp"
        :commentType="comment.Context__c"
        :message="comment.Text__c"
        :pitchdeckURL="comment.Pitcheck_URL"
      />
    </div>
  </div>
</template>
<script setup>
import { bus } from "@/main.js";
import { onMounted, onUnmounted, ref, watchEffect, computed } from "vue";
import {
  getComments,
  getDocumentsComment
} from "../services/comments-service.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";
const userAuthStore = useUserAuthStore();
const isLoading = ref(true);
const comments = ref(null);
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const props = defineProps({
  opportunityId: {
    type: String,
    default: null
  },
  startupId: {
    type: String,
    default: null
  },
  isSidebar: {
    type: Boolean,
    default: false
  },
  selectedChallenge: {
    type: String,
    default: null
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  pitchdeck: {
    type: Boolean,
    default: true
  }
});

onMounted(() => {
  bus.on("reload-comments", () => {
    loadComments();
  });
});
watchEffect(() => {
  if (!comments.value && props.selectedChallenge && props.startupId) {
    setTimeout(() => {
      loadComments();
    }, 0);
  }
});
onUnmounted(() => {
  bus.off("reload-comments");
  comments.value = null;
});
const viewProfile = () => {
  router.push({
    name: `startup-profile`,
    params: {
      id: route.query?.a
    },
    ...{
      query: {
        from: props.selectedChallenge
      }
    }
  });
};

async function loadComments() {
  try {
    isLoading.value = true;
    const [sfComments, pitchdeck] = await Promise.all([
      props.opportunityId
        ? getComments({
            accountId: userAuthStore.state.accountInfo.id,
            opportunityId: props.opportunityId,
            accountType: userAuthStore.state.accountInfo.type
          })
        : Promise.resolve([]),
      props.pitchdeck
        ? getDocumentsComment({
            startupId: props.startupId,
            challengeId: props.selectedChallenge
          })
        : Promise.resolve([])
    ]);
    const pitchdeckComments = pitchdeck || [];
    comments.value = [...sfComments, ...pitchdeckComments];
  } catch (error) {
    console.log("error load comments:", error);
    //TODO improve with fallback
    comments.value = [];
  } finally {
    isLoading.value = false;
  }
}
</script>
