import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";
import { bus } from "@/main.js";

export const useStartupApplicationModal = () => {
  const StartupApplicationModal = defineAsyncComponent(
    () => import("@startups/components/startup-application-modal.vue")
  );
  const dialog = useDialog();
  const showApplicationModal = ({ currentApplication, currentChallenge }) => {
    console.log("currentApplication-->", currentApplication, currentChallenge);
    return new Promise(() => {
      dialog?.open(StartupApplicationModal, {
        data: {
          currentApplication: currentApplication,
          currentChallenge: currentChallenge
        },
        props: {
          header: currentApplication
            ? `Edit ${currentChallenge.name} application`
            : `Apply to ${currentChallenge.name}`,
          style: {
            width: "560px"
          },
          breakpoints: {
            "640px": "90vw"
          },
          modal: true,
          draggable: false,
          closable: true
        },
        onClose: (e) => {
          console.log("e?.data?.success-->", e?.data);
          if (e?.data?.success) {
            bus.emit("reload-application");
          } else {
            bus.emit("update-application-failed", currentChallenge.id);
          }
        }
      });
    });
  };
  return { showApplicationModal };
};
