<template>
  <div v-if="loadingRef" class="mt-4 flex flex-col gap-y-2">
    <div
      v-for="index in preview ? 2 : 4"
      :key="index"
      class="flex w-full h-[85px]"
    >
      <Skeleton class="w-full h-full"></Skeleton>
    </div>
  </div>
  <div
    class="mt-4 flex flex-col gap-y-2"
    v-else-if="appStateStore.state.isError?.ecosystemPublishedChallengesList"
  >
    <fallback-error @reload="reload" />
  </div>
  <div
    v-else-if="props.challenges.length === 0"
    class="mt-4 flex flex-col gap-y-2"
  >
    <fallback-empty-state
      :message="
        !filteredChallenges
          ? 'Your corporate partners have not created a business challenge yet.'
          : 'Corporates have not created a business challenge yet.'
      "
    />
  </div>

  <DataTable
    v-else
    :sortField="sort.sortField"
    :sortOrder="sort.sortDirection"
    :value="filteredChallenges"
    scrollHeight="flex"
    :paginator="!props.preview && !isChromium"
    :paginatorTemplate="
      !props.preview && !isChromium
        ? 'PrevPageLink CurrentPageReport NextPageLink'
        : null
    "
    :currentPageReportTemplate="
      !props.preview && !isChromium ? '{currentPage} of {totalPages}' : null
    "
    :virtualScrollerOptions="
      props.preview || !isChromium
        ? null
        : {
            itemSize: 85
          }
    "
    :rows="!props.preview && !isChromium ? rows : null"
    scrollable
    dataKey="Id"
    v-model:filters="filters"
    @row-click="openSidebar"
    filterDisplay="menu"
    :pt="getTableStyle()"
  >
    <!-- <template #header>
      <div class="flex justify-content-end">
        <span class="p-input-icon-left"> you can add something here </span>
      </div>
    </template> -->
    <Column style="width: 3rem" :frozen="true" :preview="preview || search">
      <template #body="{ data }">
        <base-logo
          v-if="
            isVisible(
              data.Challenge_Visibility__c.index,
              data.Challenge_Corporate_Sponsor__c
            )
          "
          classes="h-8 w-8"
          :companyId="data.Challenge_Corporate_Sponsor__c"
        />

        <base-vite-icon v-else name="hide" classes="fill-grey3 h-6 w-6 " />
      </template>
    </Column>
    <Column
      field="Name"
      filterField="Name.name"
      :style="`width: ${preview ? '350px' : '200px'}`"
      :frozen="true"
      :preview="preview || search"
    >
      <!-- :class="sort.sortField === 'Name.name' ? 'font-bold' : 'font-normal'" -->
      <template #header v-if="!preview && !search">
        <!-- NOTE: field is import for sorting -->
        <base-table-filter-multiselect
          :filterModel="filters.Name.value"
          :sortingModel="sort"
          field="Name.name"
          staticLabel="Challenge"
          :options="filters.Name.options"
          @updateSort="
            (value) => {
              sort = value ? value : preSort;
            }
          "
          @updateFilter="
            (value) => {
              filters.Name.value = value;
            }
          "
        />
      </template>

      <template #body="{ data }">
        <div class="flex items-center gap-2">
          <span
            v-tooltip.top="preview ? 'Challenge Name' : null"
            class="whitespace-nowrap font-bold text-ellipsis overflow-hidden block text-lg"
            :class="preview ? 'max-w-[350px]' : 'max-w-[200px]'"
          >
            {{ data.Name.name }}
          </span>
          <base-sponsorship-badge :id="data.Id" />
        </div>
      </template>
    </Column>
    <Column
      field="Challenge_Corporate_Sponsor__r.Name"
      style="width: auto; min-width: 20%"
      :preview="preview || search"
    >
      <template #header v-if="!preview && !search">
        <!-- NOTE: field is import for sorting -->
        <base-table-filter-multiselect
          :filterModel="filters['Challenge_Corporate_Sponsor__r.Name'].value"
          staticLabel="Corporate"
          :sortingModel="sort"
          field="Challenge_Corporate_Sponsor__r.Name.name"
          :options="filters['Challenge_Corporate_Sponsor__r.Name'].options"
          @updateSort="
            (value) => {
              sort = value ? value : preSort;
            }
          "
          @updateFilter="
            (value) => {
              filters['Challenge_Corporate_Sponsor__r.Name'].value = value;
            }
          "
        />
      </template>
      <template #body="{ data }">
        <span
          class="text-base"
          v-tooltip.top="preview ? 'Corporate Name' : null"
        >
          {{
            isVisible(
              data.Challenge_Visibility__c.index,
              data.Challenge_Corporate_Sponsor__c
            )
              ? data.Challenge_Corporate_Sponsor__r?.Name?.name
              : "--"
          }}</span
        >
      </template>
    </Column>
    <Column
      :preview="preview || search"
      filterField="Challenge_Objective__c.name"
      style="width: auto; min-width: 15%"
    >
      <template #header v-if="!preview && !search">
        <base-table-filter-multiselect
          :filterModel="filters.Challenge_Objective__c.value"
          staticLabel="Objective"
          :sortingModel="sort"
          field="Challenge_Objective__c.name"
          :options="filters.Challenge_Objective__c.options"
          @updateSort="
            (value) => {
              sort = value ? value : preSort;
            }
          "
          @updateFilter="
            (value) => {
              filters.Challenge_Objective__c.value = value;
            }
          "
        />
      </template>
      <template #body="{ data }">
        <div
          v-tooltip.top="preview ? 'Challenge Objective' : null"
          :class="`mtb-chip text-sm ${
            objectiveMapping[data.Challenge_Objective__c?.index]
              ?.parentClasses || objectiveMapping.default.parentClasses
          }`"
        >
          <base-vite-icon
            :name="
              objectiveMapping[data.Challenge_Objective__c?.index]?.icon ||
              objectiveMapping.default.icon
            "
            :classes="`${
              objectiveMapping[data.Challenge_Objective__c?.index]
                ?.iconClasses || objectiveMapping.default.iconClasses
            } h-4 w-4`"
          />{{
            objectiveMapping[data.Challenge_Objective__c?.index]?.label ||
            data.Challenge_Objective__c.index
          }}
        </div>
      </template>
    </Column>
    <Column
      field="CreatedDate"
      style="width: auto; min-width: 20%"
      v-if="!preview && !search"
    >
      <template #header v-if="!search">
        <base-table-filter-multiselect
          :sortingModel="sort"
          field="CreatedDate"
          @updateSort="
            (value) => {
              sort = value ? value : preSort;
            }
          "
          staticLabel="Publication date"
      /></template>
      <template #body="{ data }">
        <span class="text-base">
          {{ format(new Date(data.CreatedDate), "dd MMM yyyy") }}</span
        >

        <!-- <Tag :value="data.status" :severity="getSeverity(data.status)" /> -->
      </template>
    </Column>
    <Column
      :preview="preview || search"
      field="Challenge_Visibility__c"
      filterField="Challenge_Visibility__c.name"
      style="width: auto; min-width: 15%"
      v-if="!preview"
    >
      <template #header v-if="!preview && !search">
        <base-table-filter-multiselect
          :filterModel="filters.Challenge_Visibility__c.value"
          :sortingModel="sort"
          field="Challenge_Visibility__c.name"
          staticLabel="Visibility"
          :options="filters.Challenge_Visibility__c.options"
          @updateSort="
            (value) => {
              sort = value ? value : preSort;
            }
          "
          @updateFilter="
            (value) => {
              filters.Challenge_Visibility__c.value = value;
            }
          "
      /></template>
      <template #body="{ data }">
        <div
          :class="`mtb-chip text-sm ${
            visibilityMapping[data.Challenge_Visibility__c?.name]?.parentClasses
          }`"
        >
          <base-vite-icon
            :name="visibilityMapping[data.Challenge_Visibility__c?.name]?.icon"
            :classes="`${
              visibilityMapping[data.Challenge_Visibility__c?.name]?.iconClasses
            } h-4 w-4`"
          />{{ visibilityMapping[data.Challenge_Visibility__c?.name]?.label }}
        </div>
      </template>
    </Column>
    <Column
      v-if="props.internal"
      style="width: 2rem"
      :frozen="true"
      alignFrozen="right"
    >
      <template #body="{ data }">
        <MTB-Button
          type="button"
          @click.stop="toggle($event, data.Id)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          :pt="{
            root: 'border-none h-5 flex'
          }"
        >
          <template #icon>
            <base-vite-icon name="menu" classes="fill-mtb stroke-mtb h-5" />
          </template>
        </MTB-Button>
        <MTB-Menu
          ref="menu"
          id="overlay_menu"
          :model="advisorOptions"
          :popup="true"
          :pt="{
            root: 'py-1 bg-white text-black border border-grey3  rounded-md w-48',
            menu: {
              class: ['m-0 p-0 list-none', 'outline-none']
            },

            action: {
              class: [
                'text-black text-sm font-sans py-2 px-3 select-none',
                'cursor-pointer flex items-center no-underline overflow-hidden relative'
              ]
            }
          }"
        >
          <template #item="{ item, props }">
            <router-link
              v-if="item.route"
              v-slot="{ href, navigate }"
              :to="item.route"
              custom
            >
              <a :href="href" v-bind="props.action" @click="navigate">
                <span class="ml-2 text-sm">{{ item.label }}</span>
              </a>
            </router-link>
            <a
              v-else-if="item.url"
              :href="item.url"
              target="_blank"
              v-bind="props.action"
            >
              <span class="ml-2 text-sm">{{ item.label }} </span>
            </a></template
          ></MTB-Menu
        >
      </template>
    </Column>
    <Column
      style="width: 2rem"
      :frozen="true"
      alignFrozen="right"
      :preview="preview || search"
    >
      <template #body>
        <span class="cursor-pointer flex justify-end">
          <base-vite-icon
            class="w-1/5"
            name="arrow-short-right"
            classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
        /></span>
      </template>
    </Column>
    <template #empty
      >No challenges match your filter criteria. Please adjust your filters and
      try again.</template
    >
  </DataTable>
</template>
<script setup>
import { format } from "date-fns";
import { useScreenHeight } from "@global/hooks/use-screen-height.js";
import { computed, onMounted, ref } from "vue";
import { FilterMatchMode } from "primevue/api";
import { useChallengesStore } from "../stores/challenges-store.js";
import { useAppStateStore } from "../stores/app-state-store.js";
import {
  visibilityMapping,
  objectiveMapping
} from "@global/configs/challenge-mapping.js";
import { bus } from "@/main.js";
import { makeUniqueArray } from "@global/helpers/array.js";
import { v4 as uuidv4 } from "uuid";
import { useFetch } from "../hooks/use-fetch.js";
import { useFilterModal } from "@/global/hooks/use-filter-modal.js";
import { useFilterUrlBind } from "../hooks/use-filter-url-bind.js";
import { default as mapping } from "@share/mappingFilter.js";
import { useCustomFilterMode } from "../hooks/use-custom-filter-mode.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { useLoading } from "../hooks/use-loading.js";
const { showFilters } = useFilterModal();
const { MULTI_PICKLIST } = useCustomFilterMode();
import { isCorporateChallengeVisible } from "@global/helpers/util.js";
import { useTablePassthrough } from "@global/hooks/use-table-passthrough.js";
const { getTableStyle } = useTablePassthrough();

const props = defineProps({
  challenges: {
    type: Array,
    default: () => []
  },
  preview: {
    type: Boolean,
    default: false
  },
  search: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  allChallenges: {
    type: Boolean,
    default: true
  },
  internal: {
    type: Boolean,
    default: false
  },
  optionsData: {
    type: [Array, null],
    default: null
  }
});
const {
  isGlobalUserInfoLoading,
  isAllChallengesListLoading,
  isCorporateListLoading
} = useLoading();
const storesActions = useFetch();
const userAuthStore = useUserAuthStore();
const appStateStore = useAppStateStore();
const challengesStore = useChallengesStore();
const opportunitiesStore = useOpportunitiesStore();

const isChromium = !!window.chrome;

//Filter out hidden challenge if the user isn't the sponsor
const filteredChallenges = computed(() => {
  return props.challenges?.reduce((acc, challenge) => {
    //NOTE: For field that needs to use index instead of name => format it with object format {name,index}
    const formattedChallenge = {
      ...challenge,
      Name: { name: challenge.Name, index: challenge.Id },
      //DEBT: change naming
      Challenge_Corporate_Sponsor__r: {
        Name: {
          name: isVisible(
            challenge.Challenge_Visibility__c,
            challenge.Challenge_Corporate_Sponsor__c
          )
            ? challenge.Challenge_Corporate_Sponsor__r?.Name
            : null,
          index: isVisible(
            challenge.Challenge_Visibility__c,
            challenge.Challenge_Corporate_Sponsor__c
          )
            ? challenge.Challenge_Corporate_Sponsor__r
            : null
        }
      },
      Challenge_Objective__c: {
        name:
          objectiveMapping[challenge.Challenge_Objective__c]?.label ||
          challenge.Challenge_Objective__c,
        index: challenge.Challenge_Objective__c
      },
      Challenge_Visibility__c: {
        name: challenge.Challenge_Visibility__c,
        index: challenge.Challenge_Visibility__c
      }
    };
    if (
      challenge.Challenge_Visibility__c.name !== "Hidden" ||
      isSposnoredChallenge(challenge.Challenge_Corporate_Sponsor__c)
    ) {
      acc.push(formattedChallenge);
    }
    return acc;
  }, []);
});
const optionsDataset = computed(() => {
  return props.optionsData || filteredChallenges.value;
});
const challengeVerticalsOption = computed(() => {
  const verticals = new Set(
    props.challenges.flatMap((ele) => ele.Challenge_Verticals__c?.split(";"))
  );
  const verticalArray = Array.from(verticals)
    .filter((e) => e)
    .sort((a, b) => a.localeCompare(b))
    .map((e) => {
      return { name: e, index: e };
    });
  return verticalArray;
});
function openSidebar(e) {
  e.originalEvent.stopImmediatePropagation();

  const opportunityId = opportunitiesStore.state.startupOpportunities?.find(
    (e) => {
      return e.challengeId === e.data?.Id;
    }
  )?.id;

  bus.emit("open-application-detail-sidebar", {
    challengeId: e.data.Id,
    ...(opportunityId &&
      userAuthStore.state.accountInfo?.type == "startup" && {
        opportunityId: opportunityId
      })
  });
}
function isVisible(visibility, corporateSponsor) {
  return isCorporateChallengeVisible({
    Challenge_Corporate_Sponsor__c: corporateSponsor,
    Challenge_Visibility__c: visibility
  });
}
function isSposnoredChallenge(accountOwnerId) {
  return !!challengesStore.getters.getChallengesForSponsoredCorporates.find(
    (challenge) => challenge.Challenge_Corporate_Sponsor__c === accountOwnerId
  );
}
//SORTING BY DEFAULT
const filters = ref({
  //Needing to add also the Id
  Name: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        filteredChallenges.value.map((challenge) => challenge.Name),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name.localeCompare(b.name)
      );
    }),
    name: "Name",
    modalLabel: "Challenge",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: mapping.filterKeys.CHALLENGE,
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return (
          //Case: option are not the same between tabs
          filteredChallenges.value.find(({ Id }) => Id === item)?.Name
        );
      });
    }
  },
  "Challenge_Corporate_Sponsor__r.Name": {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        filteredChallenges.value.reduce((acc, curr) => {
          if (
            isVisible(
              curr.Challenge_Visibility__c.index,
              curr.Challenge_Corporate_Sponsor__c
            )
          ) {
            acc.push(curr.Challenge_Corporate_Sponsor__r.Name);
          }
          return acc;
        }, []),
        (a, b) => a.name === b.name
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name.localeCompare(b.name)
      );
    }),
    name: "Challenge_Corporate_Sponsor__r.Name",
    modalLabel: "Corporate",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: "_accId",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return filteredChallenges.value.find(
          ({ Challenge_Corporate_Sponsor__c }) =>
            Challenge_Corporate_Sponsor__c === item
        )?.Challenge_Corporate_Sponsor__r?.Name;
      });
    }
  },
  Challenge_Objective__c: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        filteredChallenges.value.map((ele) => {
          return ele.Challenge_Objective__c;
        }),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name.localeCompare(b.name)
      );
    }),
    //TODO use same obj as corporate
    name: "Challenge_Objective__c",
    modalLabel: "Objective",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: mapping.filterKeys.OBJECTIVE,
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return filteredChallenges.value.find(({ Challenge_Objective__c }) => {
          return Challenge_Objective__c.index === item;
        }).Challenge_Objective__c;
      });
    }
  },
  //TODO: double check
  // CreatedDate: {
  //   value: null,
  //   matchMode: FilterMatchMode.CONTAINS
  // },
  Challenge_Visibility__c: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        filteredChallenges.value.map((ele) => {
          return ele.Challenge_Visibility__c;
        }),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name.localeCompare(b.name)
      );
    }),
    name: "Challenge_Visibility__c",
    modalLabel: "Visibility",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: "_visb",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return filteredChallenges.value.find(({ Challenge_Visibility__c }) => {
          return Challenge_Visibility__c.index === item;
        })?.Challenge_Visibility__c;
      });
    }
  },
  //TODO: need to format the value of filter list
  Challenge_Verticals__c: {
    value: null,
    matchMode: MULTI_PICKLIST.value,
    options: computed(() => {
      return challengeVerticalsOption.value;
    }),
    name: "Challenge_Verticals__c",
    modalLabel: "Industry Verticals",
    index: uuidv4(),
    filterType: "multiselect",
    filterUrlKey: mapping.filterKeys.VERTICAL,
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return challengeVerticalsOption.value.find(({ index }) => {
          return index === item;
        });
      });
    }
  }
});

const loadingRef = computed(() => {
  return (
    isGlobalUserInfoLoading.value ||
    isAllChallengesListLoading.value ||
    isCorporateListLoading.value ||
    props.isLoading ||
    isProcessing.value
  );
});
import { useProcessing } from "@/global/hooks/use-processing.js";
const { isProcessing } = useProcessing(filteredChallenges);
!props.search && useFilterUrlBind(filters, loadingRef);
// const numberOfFilters = computed(() =>
//   Object.values(filters.value).reduce((acc, curr) => {
//     if (curr.value && curr.value.length > 0) {
//       acc++;
//     }
//     return acc;
//   }, 0)
// );
const preSort = {
  sortField: "CreatedDate",
  sortDirection: -1
};
const sort = ref(preSort);
const rows = useScreenHeight(85, 170);
////ROW CALC
import { useFilterLogic } from "@global/hooks/use-filter-logic.js";
const { forceExport } = useFilterLogic(
  filters,
  showFilters,
  null,
  props.search
);

function reload() {
  storesActions.setEcosystemPublishedChallengesList.refresh();
}
//advisor only
const menu = ref(null);
const advisorOptions = ref([]);
const toggle = (event, id) => {
  advisorOptions.value = [
    {
      label: "Open in Salesforce",
      url: `https://mindthebridge.lightning.force.com/lightning/r/Challenges/${id}/view`
    }
    // {
    //   label: "Rialto Recommender",
    //   route: { name: "rialto-challenge-suggest", params: { chid: id } }
    // }
  ];
  menu.value.toggle(event);
};
onMounted(() => {
  setTimeout(() => {
    !props.search && forceExport();
  }, 100);
});
</script>
