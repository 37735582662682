import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";

export function useFilterModal() {
  const ModalFilter = defineAsyncComponent(
    () => import("@global/components/base-modal-filter.vue")
  );
  const dialog = useDialog();
  const showFilters = (filters, selectedCategory) => {
    console.log("@@@selectedCategory", selectedCategory);
    const filterCategories = Object.values(filters).reduce((acc, curr) => {
      if (curr.filterType) {
        acc.push({
          name: curr.name,
          index: curr.index,
          options: curr.options,
          currentFilterValue: curr.value,
          modalLabel: curr.modalLabel,
          filterType: curr.filterType,
          ...(curr.minDate && { minDate: curr.minDate })
        });
      }
      return acc;
    }, []);
    dialog?.open(ModalFilter, {
      data: {
        filterCategories,
        selectedCategory:
          filterCategories?.find(
            (ele) => ele?.name === selectedCategory?.name
          ) || filterCategories[0]
      },
      props: {
        header: "Filter",
        style: {
          width: "50vw"
        },
        breakpoints: {
          "960px": "75vw",
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  };
  return {
    showFilters
  };
}
